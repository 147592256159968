@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "DM Sans", sans-serif !important;
    font-feature-settings: "kern" !important;
    -webkit-font-smoothing: antialiased;
    letter-spacing: -0.5px;
  }
}



input.defaultCheckbox::before {
  content: url(../src/assets/svg/checked.svg);
  color: white;
  opacity: 0;
  height: 16px;
  width: 16px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0px);
}

input:checked.defaultCheckbox::before {
  opacity: 1;
}

input#password1,
input#password2,
input#password3,
input#password4,
input#password5 {
  text-align: center;
}

.mb-10R {
  margin-bottom: 10rem;
}

/* .bg-white {
  background-color: white !important;
} */
.signIn-notes {
  padding: 0 2px 0 5px;
  text-align: left;
}
.mt-20P {
  margin-top: -20px;
}
.ml-20P {
  margin-left: 7px;
  margin-bottom: 25px;
  font-size: 1.3rem !important;
}

.greeting {
  margin-bottom: 5px;
  font-size: 1.3rem !important;
}

.chakra-modal__content-container.css-1oxhx2p {
  top: 20px !important;
}

#main-body > div.chakra-portal > div:nth-child(3) > div {
  z-index: 0;
}

#main-body > div.chakra-portal > div.chakra-modal__overlay.bg-\[\#000\].bg-opacity-50.css-13d1tkw {
  z-index: 0;
}

.list-scroll {
  max-height: 350px;
  overflow: auto;
}

.list-scroll-d {
  max-height: 80vh;
  overflow: auto;
}

.list-border:hover,
.list-border:selected {
  border: 1px solid #f97316;
  content: "";
  border-radius: 2%;
}

input:focus {
  outline: none !important;
  border: 1px solid #f97315 !important;
  box-shadow: 0 0 10px #f97315 !important;
}

input#password1:focus {
  outline: none !important;
  border: 1px solid #f97315 !important;
  box-shadow: 0 0 10px #f97315 !important;
}

.inputWithButton {
  position: relative;
}

.inputWithButton input {
  padding-right: 155px;
}

.inputWithButton button {
  position: absolute;
  right: 0;
  top: 10px;
}


.noHover:active {
  border: none !important;
  border: none !important;
  box-shadow: none !important;
}

.noHover:focus {
  box-shadow: none !important;
}




/*Toggle click design  */
/* Container for the whole toggle */
.toggle-container {
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: start;
}

.switch {
  position: relative;
  display: inline-block;
  width: 69px;
  height: 38px;
  border-radius: 30px;
  background-color: #ffffff;
  box-sizing: border-box;
  background:#3B82F6;
}
.eventSwitch {
  height: 48px;
  width: 80px;
  background: var(--Orange-100, #FFEDD5);
  box-shadow: 0px 1.048px 1.048px 0px rgba(0, 0, 0, 0.14) inset, 0px 1.048px 1.048px 0px rgba(255, 255, 255, 0.24);

}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 3px;
  left: 3px;
  right: 3px;
  bottom: 3px;
  transition: 0.4s;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  
}

input:checked + .slider {
  /* background-color: #ffffff; */
  /* left: calc(100% - 3px);
  transform: translateX(-100%); */
}

.switch-icon {
  font-size: 12px;
  /* color: #ffffff;
  background:white;  */
  border-radius: 50%;
  width: 32px;
  height: 32px;
  pointer-events: none;
}

input:checked + .slider .switch-icon {
  color: #4285f4;
}

.labels {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  margin-top: 5px;
  color: #6B7280;


}

.labels span {
  font-size: 16px;
}

.labels .active {
  color: #6B7280;
  font-weight: bold;
}

.hug-button {
  background-color: #a270f5;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  margin-top: 10px;
}

/*Toggle click design  */

:where(.css-dev-only-do-not-override-ccdg5a).ant-btn-sm {
  background: chocolate !important;
  color: white !important;
}

.ant-picker-outlined{
  border: none !important;
  box-shadow: none !important;
}

.ant-picker-input > input:focus{
  border: none !important;
  box-shadow: none !important;
}
